<template>
    <div class="layout-topbar shadow-4 inari-bg-alt">
        <div class="layout-topbar-left inari-bg">
            <router-link class="layout-topbar-logo" to="/">
                <!-- <h2 class="text-light m-0 fg-white pl-0" style="line-height: 52px">E<span class="fg-yellow">N</span>AT⚡INARI</h2> -->
                <!-- <h2 class="text-light m-0 fg-white pl-0" style="line-height: 52px">E<span class="fg-yellow">N</span>AT <i class="fi fi-rr-fox text-2xl"></i> INARI</h2> -->
                <img src="layout/images/inari_logo_web.png" alt="ENAT INARI" class="layout-topbar-logo pr-1" />
            </router-link>

            <a class="layout-menu-button shadow-6 p-ripple bg-lightGray" @click="onMenuButtonClick($event)" v-ripple>
                <i class="pi pi-chevron-right"></i>
            </a>

            <a class="layout-topbar-mobile-button p-ripple" @click="onTopbarMobileButtonClick($event)" v-ripple>
                <i class="pi pi-ellipsis-v fs-large"></i>
            </a>
        </div>

        <div class="layout-topbar-right" :class="{ 'layout-topbar-mobile-active': mobileTopbarActive }">
            <div class="layout-topbar-actions-left">
                <!-- <MegaMenu :model="items" class="layout-megamenu"></MegaMenu> -->
            </div>

            <div class="layout-topbar-actions-right">
                <ul class="layout-topbar-items">

                    <li class="layout-topbar-item notifications">
                        <a class="layout-topbar-action p-ripple" @click="onTopbarItemClick($event, 'notifications')" v-ripple @dblclick="this.$router.push('/alarms');">
                            <span :key="getAlarmCount" class="p-overlay-badge" v-badge="getAlarmCount">
                                <i class="pi pi-bell fs-large" :class="getAlarmCount > 0 ? 'fg-yellow pi-spin' : ''"></i>
                            </span>
                        </a>

                        <transition name="layout-topbar-action-panel">
                            <ul v-if="getAlarmCount > 0" class="layout-topbar-action-panel shadow-6" v-show="activeTopbarItem === 'notifications'">
                                <li class="mb-3">
                                    <span v-if="getAlarmCount > 0" class="px-3 fs-small">Aktuell <b>{{ getAlarmCount }}</b> aktive Alarme:</span>
                                    <span v-else class="px-3 fs-small">Derzeit gibt es keine Alarme</span>
                                </li>
                                <li v-for="(value, key) of alarmList" :key="key" class="layout-topbar-action-item">
                                    <router-link :to="'/alarms'">
                                        <div class="flex flex-row align-items-center">
                                            <Avatar :label="value.department" class="mr-1 font-semibold bg-lightLime fg-crimson" />
                                            <div class="flex flex-column" :class="{ 'ml-3': !isRTL, 'mr-3': isRTL }" style="flex-grow: 1">
                                                <div class="flex align-items-center justify-content-between mb-1">
                                                    <span class="fs-small font-bold fg-yellow">{{ value.alarmLabel }}</span>
                                                    <Rating v-model="value.alarmPrior" :stars="3" readonly :cancel="false" />
                                                </div>
                                                <span class="fs-small">{{ formatAlarmDate(value.timestamp) }}</span>
                                                <span class="fs-small">{{ value.moduleLabel }}</span>
                                                <span class="fs-small">{{ value.alarmDesc }}</span>
                                            </div>
                                        </div>
                                    </router-link>
                                </li>
                            </ul>
                        </transition>
                    </li>

                    <li class="layout-topbar-item app">
                        <a class="layout-topbar-action rounded-circle p-ripple" @click="onTopbarItemClick($event, 'apps')" v-ripple>
                            <i class="pi pi-bolt fs-large"></i>
                        </a>

                        <transition name="layout-topbar-action-panel">
                            <div class="grid grid-nogutter layout-topbar-action-panel shadow-6" v-show="activeTopbarItem === 'apps'">
                                <div class="layout-topbar-action-item col-4">
                                    <a class="flex align-items-center flex-column text-color p-ripple" v-ripple>
                                        <span class="fs-small">Verbindungen</span>
                                        <i class="pi pi-users action bg-amber fg-crimson"></i>
                                        <span class="font-bold">{{ connectionCount }}</span>
                                    </a>
                                </div>
                                <div class="layout-topbar-action-item col-4">
                                    <a class="flex align-items-center flex-column text-color p-ripple" v-ripple>
                                        <span class="fs-small">Client</span>
                                        <i class="pi pi-clock action bg-blue fg-crimson"></i>
                                        <span class="font-bold">{{ sessionId }}</span>
                                    </a>
                                </div>
                                <div class="layout-topbar-action-item col-4">
                                    <a class="flex align-items-center flex-column text-color p-ripple" v-ripple>
                                        <span class="fs-small">Server</span>
                                        <i class="pi pi-server action bg-darkBlue fg-crimson"></i>
                                        <span class="font-bold">{{ runTimeFormatted }}</span>
                                    </a>
                                </div>
                                <div class="layout-topbar-action-item col-4">
                                    <a class="flex align-items-center flex-column text-color p-ripple" v-ripple>
                                        <span class="fs-small">Status</span>
                                        <i class="pi pi-power-off action bg-yellow fg-crimson"></i>
                                        <span class="font-bold">{{ systemStatus }}</span>
                                    </a>
                                </div>
                                <div class="layout-topbar-action-item col-4">
                                    <a class="flex align-items-center flex-column text-color p-ripple" v-ripple>
                                        <span class="fs-small">Version</span>
                                        <i class="pi pi-info-circle action bg-lightMauve fg-crimson"></i>
                                        <span class="font-bold">{{ version }}</span>
                                    </a>
                                </div>
                                <div class="layout-topbar-action-item col-4">
                                    <a class="flex align-items-center flex-column text-color p-ripple" v-ripple>
                                        <span class="fs-small">Systemnummer</span>
                                        <i class="pi pi-hashtag action bg-lightRed fg-crimson"></i>
                                        <span class="font-bold">{{ clientId }}</span>
                                    </a>
                                </div>
                            </div>
                        </transition>
                    </li>

                    <li class="layout-topbar-item">
                        <a class="layout-topbar-action flex flex-row justify-content-center align-items-center px-2 rounded-circle p-ripple" @click="onTopbarItemClick($event, 'profile')" v-ripple>
                            <img src="layout/images/avatar/enat.png" alt="avatar" style="width: 32px; height: 32px" />
                        </a>

                        <transition name="layout-topbar-action-panel">
                            <ul class="layout-topbar-action-panel shadow-6" v-show="activeTopbarItem === 'profile'">
                                <router-link to="/userprofile">
                                    <li class="layout-topbar-action-item">
                                        <a class="flex flex-row align-items-center p-ripple" v-ripple>
                                            <i class="pi pi-user" :class="{ 'mr-2': !isRTL, 'ml-2': isRTL }"></i>
                                            <span>Benutzerprofil</span>
                                        </a>
                                    </li>
                                </router-link>
                                <router-link to="/logout">
                                    <li class="layout-topbar-action-item">
                                        <a class="flex flex-row align-items-center p-ripple" v-ripple>
                                            <i class="pi pi-power-off" :class="{ 'mr-2': !isRTL, 'ml-2': isRTL }"></i>
                                            <span>Logout</span>
                                        </a>
                                    </li>
                                </router-link>
                            </ul>
                        </transition>
                    </li>

                    <li v-if="checkUser()" class="layout-topbar-item">
                        <a class="layout-topbar-action rounded-circle p-ripple" @click="onRightPanelButtonClick($event)" v-ripple>
                            <i class="pi fs-large" :class="{ 'pi-arrow-left': !isRTL, 'pi-arrow-right': isRTL }"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { Role, formatDateGui } from './helpers';
import { mapGetters } from "vuex";

export default {
    emits: ['menubutton-click', 'topbar-menubutton-click', 'topbaritem-click', 'rightpanel-button-click', 'topbar-mobileactive', 'search-click', 'search-toggle'],
    data() {
        return {
            searchText: '',
            items: [
                {
                    label: 'UI KIT',
                    items: [
                        [
                            {
                                label: 'UI KIT 1',
                                items: [
                                    { label: 'Form Layout', icon: 'pi pi-fw pi-id-card', to: '/formlayout' },
                                    { label: 'Input', icon: 'pi pi-fw pi-check-square', to: '/input' },
                                    { label: 'Float Label', icon: 'pi pi-fw pi-bookmark', to: '/floatlabel' },
                                    { label: 'Button', icon: 'pi pi-fw pi-mobile', to: '/button' },
                                    { label: 'File', icon: 'pi pi-fw pi-file', to: '/file' },
                                ],
                            },
                        ],
                        [
                            {
                                label: 'UI KIT 2',
                                items: [
                                    { label: 'Table', icon: 'pi pi-fw pi-table', to: '/table' },
                                    { label: 'List', icon: 'pi pi-fw pi-list', to: '/list' },
                                    { label: 'Tree', icon: 'pi pi-fw pi-share-alt', to: '/tree' },
                                    { label: 'Panel', icon: 'pi pi-fw pi-tablet', to: '/panel' },
                                    { label: 'Chart', icon: 'pi pi-fw pi-chart-bar', to: '/chart' },
                                ],
                            },
                        ],
                        [
                            {
                                label: 'UI KIT 3',
                                items: [
                                    { label: 'Overlay', icon: 'pi pi-fw pi-clone', to: '/overlay' },
                                    { label: 'Media', icon: 'pi pi-fw pi-image', to: '/media' },
                                    { label: 'Menu', icon: 'pi pi-fw pi-bars', to: '/menus' },
                                    { label: 'Message', icon: 'pi pi-fw pi-comment', to: '/messages' },
                                    { label: 'Misc', icon: 'pi pi-fw pi-circle-off', to: '/misc' },
                                ],
                            },
                        ],
                    ],
                },
                {
                    label: 'UTILITIES',
                    items: [
                        [
                            {
                                label: 'UTILITIES 1',
                                items: [
                                    { label: 'Display', icon: 'pi pi-fw pi-desktop', to: '/display' },
                                    { label: 'Elevation', icon: 'pi pi-fw pi-external-link', to: '/elevation' },
                                ],
                            },
                            {
                                label: 'UTILITIES 2',
                                items: [{ label: 'FlexBox', icon: 'pi pi-fw pi-directions', to: '/flexbox' }],
                            },
                        ],
                        [
                            {
                                label: 'UTILITIES 3',
                                items: [{ label: 'Icons', icon: 'pi pi-fw pi-search', to: '/icons' }],
                            },
                            {
                                label: 'UTILITIES 4',
                                items: [
                                    { label: 'Text', icon: 'pi pi-fw pi-pencil', to: '/text' },
                                    { label: 'Widgets', icon: 'pi pi-fw pi-star', to: '/widgets' },
                                ],
                            },
                        ],
                        [
                            {
                                label: 'UTILITIES 5',
                                items: [
                                    { label: 'Grid System', icon: 'pi pi-fw pi-th-large', to: '/grid' },
                                    { label: 'Spacing', icon: 'pi pi-fw pi-arrow-right', to: '/spacing' },
                                    { label: 'Typography', icon: 'pi pi-fw pi-align-center', to: '/typography' },
                                ],
                            },
                        ],
                    ],
                },
            ],
        };
    },
    props: {
        horizontal: {
            type: Boolean,
            default: false,
        },
        topbarMenuActive: {
            type: Boolean,
            default: false,
        },
        activeTopbarItem: String,
        mobileTopbarActive: Boolean,
        searchActive: Boolean,
    },
    mounted() {
    },
    computed: {
        ...mapGetters({
            editMode: 'getEditMode',
            currentTimeFormatted: 'currentTimeFormatted',
            startTimeFormatted: 'startTimeFormatted',
            runTimeFormatted: 'runTimeFormatted',
            connectionCount: 'connectionCount',
            sessionId: 'sessionId',
            clientId: 'clientId',
            version: 'version',
            getUser: 'auth/getUser',
            getAlarms: 'opcua/getAlarms',
            getAlarmsActive: 'opcua/getAlarmsActive',
            getAlarmCount: 'opcua/getAlarmCount',
        }),
        topbarItemsClass() {
            return [
                'topbar-items animated fadeInDown',
                {
                    'topbar-items-visible': this.topbarMenuActive,
                },
            ];
        },
        isRTL() {
            return this.$appState.RTL;
        },
        systemStatus: function () {
            return this.$store.getters.getSystemStatus;
        },
        alarmList() {
            // return Array.from(this.getAlarms.values()).sort((a, b) => b.timestamp - a.timestamp);
            return this.getAlarmsActive;
        },
        alarmCount() {
            let count = 0;
            if (this.getAlarms.size > 0) {
                this.getAlarms.forEach((value) => {
                    if (value.value === 1) count += 1;
                });
            }
            console.log(count);
            if (count < 100) return count;
            else return '99+'
        },
    },
    methods: {
        onSearchContainerClick(event) {
            this.$emit('search-click', event);
        },
        changeSearchActive(event) {
            this.$emit('search-toggle', event);
        },
        onMenuButtonClick(event) {
        this.$emit('menubutton-click', event);
        },
        onTopbarMenuButtonClick(event) {
            this.$emit('topbar-menubutton-click', event);
        },
        onTopbarItemClick(event, item) {
            if (item === 'search') {
                this.$emit('search-toggle', event);
            }

            this.$emit('topbaritem-click', { originalEvent: event, item: item });
        },
        onTopbarMobileButtonClick(event) {
            this.$emit('topbar-mobileactive', event);
        },
        onRightPanelButtonClick(event) {
            this.$emit('rightpanel-button-click', event);
        },
        onSearchKeydown(event) {
            if (event.keyCode == 13) {
                this.$emit('search-toggle', event);
            }
        },
        onEnter() {
            if (this.$refs.searchInput) {
                this.$refs.searchInput.$el.focus();
            }
        },
        logout() {
            this.$store.dispatch('auth/logout');
        },
        checkUser() {
            // const currentUser = this.$store.getters.getUser;
            if (this.getUser) {
                if (this.getUser.role === Role.Admin || this.getUser.role === Role.Master) {
                    return true;
                } else {
                    return false;
                }
            } else return false;
        },
        formatAlarmDate(timestamp) {
            return formatDateGui(timestamp);
        }
    },
};
</script>
<style scoped>
    /* .layout-topbar-action {
        color: #090909 !important;
    } */
</style>