<template>
    <Sidebar v-model:visible="d_expanded" @hide="$emit('hide', d_expanded)" @click="$emit('content-click', $event)"
        :position="isRTL ? 'left' : 'right'" :showCloseIcon="false" :baseZIndex="1000"
        class="layout-rightmenu p-sidebar-sm fs-small py-3" :class="isRTL ? 'pl-0 pr-3' : 'pl-3 pr-0'">
        <div class="online-members flex flex-column mt-3" :class="{ 'ml-3': !isRTL, 'mr-3': isRTL }">
            <h6 class="header">EDITOR</h6>
            <div class="flex flex-row flex-wrap mt-2">
                <InputSwitch v-model="editMode" /><span class="ml-2">Bearbeitungsmodus</span>
            </div>
            <template v-if="editMode">
                <div class="flex flex-row flex-warp mt-3">
                    <Button type="button" label="aktuelle Seite speichern" icon="pi pi-save" :loading="saving" @click="savePage()" />
                </div>
                <span class="mt-3"><b>SPEICHERN</b> gilt immer nur für die gerade aktive Seite!</span>
            </template>
        </div>
        <div v-if="editMode" class="online-members flex flex-column mt-6" :class="{ 'ml-3': !isRTL, 'mr-3': isRTL }">
            <h6 class="header">ELEMENTE</h6>
            <div class="flex flex-row flex-wrap">
                <Button label="Werte-Box" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'dashboardValueBox', isArray: false})" />
                <Button label="Werte-Panel" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'dashboardPanelValues', isArray: true})" />
                <Button label="Parameter-Liste" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetPanelParameter', isArray: true})" />
                <Button label="Parameter-Liste divers" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetPanelParameterList', isArray: true})" />
                <Button label="Wetter-Widget" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'dashboardWeatherBox', isArray: false})" />
                <Button label="Ventil-Widget" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'dashboardValveBox', isArray: false})" />
                <Button label="Lüftungs-Widget" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetVentilationController', isArray: false})" />
                <Button label="Heizungs-Widget" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetHeatingController', isArray: false})" />
                <Button label="Gebäude Heizungs-Widget" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetHeatingBuildingController', isArray: false})" />
                <Button label="Gewächshaus Heizungs-Widget" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetHeatingGhController', isArray: false})" />
                <Button label="Transport Heizungs-Widget" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetHeatingTransportController', isArray: false})" />
                <Button label="Heizventil-Widget" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetHeatingValve', isArray: false})" />
                <Button label="Fernwärme-Widget" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetHeatingFwController', isArray: false})" />
                <Button label="Heizzentrale-Widget" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetHeatingCentralController', isArray: false})" />
                <Button label="Schirm-Widget" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetScreenController', isArray: false})" />
                <Button label="DateTime-Widget" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetGeoDateTime', isArray: false})" />
                <Button label="WeatherStation-Widget" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetWeatherStation', isArray: false})" />
                <Button label="Temperatur Controller" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetGhTempController', isArray: false})" />
                <Button label="Energiemanager" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetEnergyManager', isArray: false})" />
                <Button label="Klimabox (GMI)" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetClimateBox', isArray: false})" />
                <Button label="Bewässerungcontroller" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetIrrigationController', isArray: false})" />
                <Button label="Düngecontroller" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetFertiController', isArray: false})" />
                <Button label="Bewässerunggruppe" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetIrrigationGroup', isArray: false})" />
                <Button label="Bewässerungsventil" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetIrrigationValve', isArray: false})" />
                <Button label="Bewässerungsunit (GMI)" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetGmiIrrigationUnit', isArray: false})" />
                <Button label="Befeuchtungs-Widget" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetSprayingController', isArray: false})" />
                <Button label="Belichtungs-Widget" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetIlluminationController', isArray: false})" />
                <Button label="DALI Belichtungs-Widget" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetIlluminationControllerDali', isArray: false})" />
                <Button label="DALI Belichtungs-Widget (Dimmer)" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetIlluminationControllerDaliDim', isArray: false})" />
                <Button label="DALI Photonenmixer-Widget" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetIlluminationPhotonMixer', isArray: false})" />
                <Button label="Umluftventilator-Widget" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetFans', isArray: false})" />
                <Button label="Frischluftventilator-Widget" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetFreshAirFan', isArray: false})" />
                <Button label="CO2-Controller-Widget" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetCo2Controller', isArray: false})" />
                <Button label="Wochenschaltuhr Widget" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetWeekdayController', isArray: false})" />
                <Button label="Alarm Controller Widget" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetAlarmController', isArray: false})" />
                <Button label="Alarm Digital Widget" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetAlarmDigital', isArray: false})" />
                <Button label="Brunnenpumpen Widget" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetWellController', isArray: false})" />
                <Button label="Silo Widget" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetWaterSiloController', isArray: false})" />
                <Button label="Charts Widget" icon="pi pi-plus" class="p-button-raised mr-2 w-full m-1"
                    @click="addWidget({component: 'widgetPanelCharts', isArray: false})" />
            </div>
            <span class="mt-3"><b>+++</b> weitere Widgets folgen</span>
        </div>
    </Sidebar>
</template>

<script>
import EventBus from './event-bus';

export default {
    name: 'AppRightPanel',
    emits: ['content-click', 'hide'],
    props: {
        expanded: Boolean,
    },
    data() {
        return {
            d_expanded: null,
            editMode: false,
            saving: false,
        };
    },
    watch: {
        expanded(value) {
            this.d_expanded = value;
            if (this.$store.getters.getPageData.editMode !== undefined) {
                this.editMode = this.$store.getters.getPageData.editMode;
            }
        },
        editMode() {
            if (this.editMode === true) {
                this.$store.dispatch('setPageData', { editMode: true });
            } else {
                this.$store.dispatch('setPageData', { editMode: false });
            }
        },
    },
    computed: {
        isRTL() {
            return this.$appState.RTL;
        },
    },
    methods: {
        addWidget(widget) {
            EventBus.emit('addWidget', widget);
        },
        savePage() {
            this.saving = true;
            console.log("saving...");
            EventBus.emit('savePage', (result) => {
                console.log("saved?");
                if (result) {
                    console.log("saved!");
                    this.saving = false;
                }
            });

        }
    }
};
</script>

<style scoped>
</style>
